import { graphql, useStaticQuery } from "gatsby"
import React from 'react';
import Grid from "../../../common/Grid"
import NonStretchedImage from "../../../common/NonStretchedImage"
import styles from './styles.module.scss';

const imagesQuery = graphql`
     query {
     image1: file(relativePath: { eq: "cases/klaveness/row/row-1.webp" }) {
     ...fluidImage
     }
     image2: file(relativePath: { eq: "cases/klaveness/row/row-2.webp" }) {
     ...fluidImage
     }
     image3: file(relativePath: { eq: "cases/klaveness/row/row-3.webp" }) {
     ...fluidImage
     }
     }
      `;

const ImageRow = (): JSX.Element =>  {
  const data = useStaticQuery(imagesQuery);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Grid>
          <NonStretchedImage className={styles.image} {...data.image1.childImageSharp} />
          <NonStretchedImage className={styles.image} {...data.image2.childImageSharp} />
          <NonStretchedImage className={styles.image} {...data.image3.childImageSharp} />
        </Grid>
      </div>
    </div>

  )
}

export default ImageRow;
