import React from 'react';
import Slider from "react-slick";
import Grid from '../../../common/Grid';
import styles from './styles.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


interface Props {
  children?: React.ReactNode;
}

const SliderComponent = ({children}: Props): JSX.Element =>  {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };

  return (
      <Grid>
        <div className={styles.container}>
          <Slider {...settings} >
            {children}
          </Slider>
        </div>
      </Grid>
  );
};

export default SliderComponent;
