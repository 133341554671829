import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import Grid from "../../common/Grid"
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import SliderComponent from "../common/Slider"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import ImageGrid from "./ImageGrid"
import ImageRow from "./ImageRow"
import styles from './styles.module.scss';

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/klaveness/branding-1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/klaveness/branding-2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/klaveness/branding-3.png" }) {
      ...fluidImage
    }
    image4: file(relativePath: { eq: "cases/klaveness/branding-4.png" }) {
      ...fluidImage
    }
    image5: file(relativePath: { eq: "cases/klaveness/branding-5.webp" }) {
      ...fluidImage
    }
    image6: file(relativePath: { eq: "cases/klaveness/branding-6.webp" }) {
      ...fluidImage
    }
    image7: file(relativePath: { eq: "cases/klaveness/branding-7.webp" }) {
      ...fluidImage
    }
    image8: file(relativePath: { eq: "cases/klaveness/logo-2.webp" }) {
      ...fluidImage
    }
    image9: file(relativePath: { eq: "cases/klaveness/branding-8.png" }) {
      ...fluidImage
    }
    image10: file(relativePath: { eq: "cases/klaveness/pattern.webp" }) {
      ...fluidImage
    }
    image11: file(relativePath: { eq: "cases/klaveness/branding-9.webp" }) {
      ...fluidImage
    }
    slide1: file(relativePath: { eq: "cases/klaveness/slider/slide-1.png" }) {
      ...fluidImage
    }
    slide2: file(relativePath: { eq: "cases/klaveness/slider/slide-2.png" }) {
      ...fluidImage
    }
    slide3: file(relativePath: { eq: "cases/klaveness/slider/slide-3.png" }) {
      ...fluidImage
    }
    slideApp1: file(relativePath: { eq: "cases/klaveness/slider/slide-app-1.png" }) {
      ...fluidImage
    }
    slideApp2: file(relativePath: { eq: "cases/klaveness/slider/slide-app-2.png" }) {
      ...fluidImage
    }
    slideApp3: file(relativePath: { eq: "cases/klaveness/slider/slide-app-3.png" }) {
      ...fluidImage
    }
  }
  `;

const Klaveness = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = ['Konseptutvikling', 'Grafisk design', 'Prototyping', 'Animasjon', 'Illustrasjon'];

  return (
    <Layout className={styles.container}>
      <TopSection project={"Ny merkevare og identitet"} deliveries={deliveries}>
        <p>
          <b>Klaveness Digital</b> er et programvareselskap med ambisjon om å revolusjonere shippingbransjen.
          Vi har hatt gleden av å utvikle deres nye identitet og merkevare.
          Vi har også vært med å utvikle kjerneproduktet CargoValue fra starten, som har resultert i verdens mest avanserte flåtestyringsverktøy.
        </p>
      </TopSection>
      <SliderComponent>
        <NonStretchedImage {...data.slide1.childImageSharp}/>
        <NonStretchedImage {...data.slide2.childImageSharp}/>
        <NonStretchedImage {...data.slide3.childImageSharp}/>
      </SliderComponent>
      <TextBlock
        header={"Om prosjektet"}
        bottom={true}
      >
        <p>
          Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac const tur ac, vestibulum at eros.
          Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <p>
          Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
          Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue.
          Etiam porta sem malesda magna mollis euismod.
        </p>
      </TextBlock>
      <NonStretchedImage {...data.image1.childImageSharp} />
      <ImageRow/>
      <SliderComponent>
        <NonStretchedImage {...data.slideApp1.childImageSharp}/>
        <NonStretchedImage {...data.slideApp2.childImageSharp}/>
        <NonStretchedImage {...data.slideApp3.childImageSharp}/>
      </SliderComponent>
      <ImageGrid/>
      <Grid>
        <NonStretchedImage className={styles.imageWrapper} {...data.image2.childImageSharp}/>
      </Grid>
      <TextBlock wide={true}>
        <p>
          Cras mattis consectetur purus sit amet fermentum.
          Morbi leo risus, porta ac const tur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
      </TextBlock>
      <Grid className={styles.columnWrapper}>
        <NonStretchedImage className={styles.half} {...data.image3.childImageSharp}/>
        <NonStretchedImage className={styles.half} {...data.image4.childImageSharp}/>
      </Grid>
      <Grid className={classnames(styles.columnWrapper, styles.bottom)}>
        <NonStretchedImage className={styles.half} {...data.image5.childImageSharp}/>
        <div className={classnames(styles.half, styles.textWrapper)}>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac const tur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <p>
            Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
            Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue. Etiam porta sem malesda magna mollis euismod.
          </p>
        </div>
      </Grid>
      <NonStretchedImage {...data.image6.childImageSharp} />
      <Grid>
        <NonStretchedImage className={styles.imageWrapper} {...data.image7.childImageSharp} />
        <NonStretchedImage className={styles.smallImageWrapper} {...data.image8.childImageSharp} />
      </Grid>
      <Grid className={styles.purpleWrapper}>
        <NonStretchedImage className={styles.imageWrapper} {...data.image9.childImageSharp} />
      </Grid>
      <NonStretchedImage {...data.image10.childImageSharp} />
      <Grid>
        <NonStretchedImage className={styles.smallImageWrapper} {...data.image11.childImageSharp} />
      </Grid>
      <Nav/>
    </Layout>
  )
};

export default Klaveness;

