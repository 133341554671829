import React from 'react';
import Klaveness from "../components/cases/Klaveness"

const Page = (): JSX.Element => (
  <>
    <Klaveness />
  </>
);

export default Page;
